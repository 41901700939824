body {
  margin: 0;
  padding: 0;
  font-family: "Georgia", serif;
}
h1, h2, h3, h4, h5{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
}
.site-container{
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height:100vh;
}

#site-wrap{
  width: 100%;
  height: 100vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1506971456216-7b494f54d588?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=802308f2ab4504df4ca8dee210c76701&auto=format&fit=crop&w=2466&q=80');
}
p{
  margin: 0;
  font-family: "Georgia", serif;
  font-size: 20px
}
.flex-container{
  display: -ms-flexbox;
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
}
.flex-one{
  -ms-flex: 1 1;
      flex: 1 1;
}
.flex-ten{
  -ms-flex: 10 1;
      flex: 10 1;
}
.flex-four{
  flex: 4;
}
.flex-six{
  flex: 6;
}
.flex-two{
  -ms-flex: 2 1;
      flex: 2 1;
}

a{
  text-decoration: none;
  color: black;
}

.mr-2{
  margin-right: 2rem;
}
.ml-2{
  margin-left: 2rem;
}
.ml-5{
  margin-left: 5rem;
}

.underline{
  text-decoration: underline;
  color:white
}

.capitalize{
  text-transform: capitalize;
}